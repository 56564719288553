import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Clients from '../components/clients'
import CtaBar from '../components/cta-bar'
import MediaList from '../components/media-list'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

import Button from 'react-bootstrap/Button'

import Testimonials from '../components/testimonials'



class MediaPage extends React.Component {
    render() {
        const media = get(this, 'props.data.allContentfulMedia.edges')
        const testimonials = get(this, 'props.data.allContentfulTestimonial.edges')
        const location = this.props.location
        const jobLink = get(this, 'props.data.allContentfulJobListingLink.edges[0].node.link')

        


        return (
            <Layout location={this.props.location}>
                <div className="inner-page-media">
                <Container>
                    <Breadcrumb location={location} crumbLabel="Media" />
                    </Container>
                    <Row className="inner-page-banner media-banner">
            <Col>
              <h2>MEDIA</h2>
              <h1>V-Tech In The News</h1>
              <a href={jobLink} target="_new"> <Button className="cta-button-banner">JOB LISTINGS</Button></a>
            </Col>
          </Row>
                    <Container>
                        <Row id="media-contact" className="space">
                            <Col>
                            <h3>V-TECH MEDIA CONTACT</h3>
                            <p>V-Tech Media Contact Sandra Yates | (202) 986-8470 | syates@v-techsolutions.net</p>
                            </Col>
                        </Row>
                        
                   <MediaList media={media}/>
                    </Container>
                    <CtaBar/>
                    <Testimonials testimonials={testimonials}/>
                    <Clients />
                </div>
            </Layout>
        )
    }
}

export default MediaPage

export const pageQuery = graphql`
  query MediaQuery {
    allContentfulMedia(sort: {fields: date, order: DESC}, filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          date
          title
          blurb {
            blurb
          }
          image {
            file {
              url
            }
          }
          file {
            file {
              url
            }
          }
        }
      }
    }
      allContentfulTestimonial(filter: {node_locale: {eq: "en-US"}}) {
        edges {
          node {
            name
            title
            quote
            image {
              file {
                url
              }
            }
          }
        }
      }
      allContentfulJobListingLink {
        edges {
          node {
            link
          }
        }
      }
}
`

import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import arrow from '../assets/images/nextIconGreen.png'
import {Animated} from "react-animated-css";

const MediaList = props => {

    const [itemsToDisplay, setItemsToDisplay] = useState(4)
    const { media } = props
    const total = media.length

    const loadMore = () => {
        const current = itemsToDisplay
        setItemsToDisplay(current + 5)
    }

    const Articles = () => {
        return (
            media.map((m, index) => {
                const date = new Date(m.node.date)
                const formattedDate = date.toLocaleDateString("en-US")
              const isVisible = () => {
                    if (index <= itemsToDisplay) { return  true }
                    else { return false }
               }
                return (
                    <Animated animationIn="slideInUp" animationOut="fadeOut" style={!isVisible() && { display:'none'}} isVisible={isVisible()}>
                    <Row key={index} className="single-media space">
                        <Col md="4">
                            <img className="media-image" src={m.node.image.file.url} />
                        </Col>
                        <Col md="8">
                            <h2 className="media-title">{m.node.title}</h2>
                            <small>{formattedDate}</small>
                            <p className="media-blurb">{m.node.blurb.blurb}</p>
                            {m.node.file && <div className="arrow-link" > <a href={m.node.file.file.url}>Read More <img src={arrow} /> </a></div>}
                        </Col>
                    </Row>
                    </Animated>
                )
            })
        )
    }



    return (
        <React.Fragment>
            <Articles />
    { itemsToDisplay < total  && <Button onClick={loadMore} className="green-button">LOAD MORE</Button> }
        </React.Fragment>
    )

}

export default MediaList